<template>
  <div>
    <van-notice-bar left-icon="volume-o" text="提示：点击姓名，可修改他的状态，出席或缺席。" />
    <van-cell-group title="会议信息">
      <van-cell title="会议主题" :value="model.TITLE" />
      <van-cell title="会议地点" :value="model.LOC" />
      <van-cell title="开会时间" :value="model.TIME | datetime('YYYY-MM-DD HH:mm')" />
      <van-cell title="主持人" :value="model.HOST" />
    </van-cell-group>
    <van-cell-group :title="'出席者 ['+attend.length+']'">
      <van-row style="text-align: center; padding: 10px 0">
        <van-col :span="6" v-for="it in attend" :key="it.ID" style="margin: 5px 0">
          <van-button type="primary"  style="width: 80%" @click="change(it)">{{it.TEA_NAME}}</van-button>
        </van-col>
      </van-row>
    </van-cell-group>
    <van-cell-group :title="'缺席者 ['+absence.length+']'">
      <van-row style="text-align: center; padding: 10px 0">
        <van-col :span="6" v-for="it in absence" :key="it.ID" style="margin: 5px 0">
          <van-button type="danger"  style="width: 80%" @click="change(it)">{{it.TEA_NAME}}</van-button>
        </van-col>
      </van-row>
    </van-cell-group>
    <div style="margin: 35px 15px">
      <van-button type="primary" size="large" icon="success" block @click="submit" :loading="submitting" loading-text="正在提交。。。">确认提交</van-button>
      <br />
      <van-button plain type="default" size="large" icon="cross" block @click="$router.go(-1)">取消返回</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "sign",
  data(){
    return{
      model:{LST_JOIN:[]},
      submitting:false
    }
  },
  created() {
    this.get();
  },
  computed:{
    attend(){return this.model.LST_JOIN.filter(o=>o.STA==1)},
    absence(){return this.model.LST_JOIN.filter(o=>o.STA==2)}
  },
  methods: {
    get() {
      let self = this;
      let g = this.$route.query.guid;
      if (g !== undefined) {
        this.whale.remote.getResult({
          url: "/api/Mobile/MEET/MinfoApi/Get",
          data: {GUID: g},
          completed(m) {
            self.model = m.DATA;
          }
        })
      }
    },
    change(o){
      o.STA=3-o.STA;
    },
    submit(){
      let arr=this.model.LST_JOIN.map(o=>o.ID+'|'+o.STA)
      let self=this;
      self.whale.remote.getResult({
        url:"/api/Mobile/MEET/MJoinApi/Sign",
        data:{
          GUID:self.model.GUID,
          JOIN:arr.join(',')
        },
        completed(){
          self.$dialog.alert({
            message:"记录已成功提交！"
          }).then(()=>{
            self.$router.go(-1);
          })
        }
      })
    }
  }
}
</script>
<style scoped></style>